import React, { useState, useRef } from 'react';
import axios from 'axios';
import './PublishCardPage.css';
import CustomDialog from './CustomDialog'; // 引入自定义弹出框
import { CKEditor } from '@ckeditor/ckeditor5-react'; // 引入 CKEditor
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'; // 引入 Classic 编辑器构建

const CardPublishPage = () => {
  const [cardData, setCardData] = useState({
    title: '',
    content: '',
    label: '',
    country: '',
    additionalInfo: '',
  });
  const [selectedImages, setSelectedImages] = useState([]); // 选中的本地图片文件
  const [error, setError] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false); // 控制弹出框的状态
  const [dialogMessage, setDialogMessage] = useState(''); // 弹出框显示的消息
  const [dialogTitle, setDialogTitle] = useState(''); // 弹出框的标题
  const [isSubmitting, setIsSubmitting] = useState(false); // 控制按钮禁用状态

  // 创建一个 ref 来访问文件输入元素
  const fileInputRef = useRef(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCardData({ ...cardData, [name]: value });
  };

  const handleContentChange = (event, editor) => {
    const data = editor.getData();
    setCardData({ ...cardData, content: data });
  };

  const handleImageChange = (e) => {
    setSelectedImages(e.target.files); // 保存用户选择的图片文件
  };

  // 上传图片到服务器
  const uploadImages = async () => {
    const token = localStorage.getItem('token');
    const uploadedImageUrls = [];

    // 如果未选择图片，返回空字符串而不是阻止提交
    if (selectedImages.length === 0) {
      return '';
    }

    for (let i = 0; i < selectedImages.length; i++) {
      const formData = new FormData();
      formData.append('image', selectedImages[i]);

      try {
        const response = await axios.post('http://romi.one:8080/common/api/resource/image', formData, {
          headers: {
            Authorization: token,
            'Content-Type': 'multipart/form-data',
          },
        });
        if (response.data) {
          uploadedImageUrls.push(response.data); // 将每个返回的图片 URL 保存
        }
      } catch (err) {
        // 显示上传失败的弹窗
        setDialogTitle('Error');
        setDialogMessage('Image upload failed, please try again.');
        setIsDialogOpen(true);
        return null;
      }
    }

    return uploadedImageUrls.join(' ');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);

    // 防止重复提交
    if (isSubmitting) return;

    // 设置提交状态为 true
    setIsSubmitting(true);

    const token = localStorage.getItem('token');

    // 上传图片并获取 URLs
    const uploadedImageUrls = await uploadImages();
    if (uploadedImageUrls === null) {
      setIsSubmitting(false); // 失败时重新允许提交
      return; // 上传图片失败时停止
    }

    const payload = {
      image: uploadedImageUrls || '', // 使用上传后的图片 URL 或空字符串
      title: cardData.title,
      content: cardData.content,
      label: cardData.label,
      country: cardData.country,
      additionalInfo: cardData.additionalInfo || '无',
    };

    try {
      const response = await axios.post('http://romi.one:8080/ireland-social/api/cards/publish', payload, {
        headers: {
          Authorization: token, // 添加 token 到请求头
        },
      });

      if (response.status === 201) {
        // 设置弹出框的标题和消息内容
        setDialogTitle('Success');
        setDialogMessage('Card published successfully!');
        // 显示弹出框
        setIsDialogOpen(true);
        // 清空表单内容
        setCardData({
          title: '',
          content: '',
          label: '',
          country: '',
          additionalInfo: '',
        });
        setSelectedImages([]); // 清空已选图片文件

        // 清空文件选择器的值
        if (fileInputRef.current) {
          fileInputRef.current.value = ''; // 清空文件输入框的显示
        }
      }
    } catch (err) {
      // 显示发布失败的弹窗
      setDialogTitle('Error');
      setDialogMessage('Failed to publish the card. Please try again.');
      setIsDialogOpen(true);
    } finally {
      // 发布完成后，允许再次提交
      setIsSubmitting(false);
    }
  };

  const closeDialog = () => {
    // 清空表单内容
    setCardData({
        title: '',
        content: '',
        label: '',
        country: '',
        additionalInfo: '',
      });
      setSelectedImages([]); // 清空已选图片文件

      // 清空文件选择器的值
      if (fileInputRef.current) {
        fileInputRef.current.value = ''; // 清空文件输入框的显示
      }
    setIsDialogOpen(false); // 关闭弹出框
  };

  return (
    <div className="card-publish-container">
      <h1 className="title">Publish a Card</h1>
      {error && <p className="error-message">{error}</p>}
      <form onSubmit={handleSubmit} className="card-form">
        <div className="form-group">
          <label>Title:</label>
          <input
            type="text"
            name="title"
            value={cardData.title}
            onChange={handleChange}
            required
            className="input-field"
          />
        </div>
        <div className="form-group">
          <label>Content:</label>
          <CKEditor
            editor={ClassicEditor}
            data={cardData.content}
            onChange={handleContentChange}
            className="rich-text-editor"
          />
        </div>
        <div className="form-group">
          <label>Label:</label>
          <input
            type="text"
            name="label"
            value={cardData.label}
            onChange={handleChange}
            className="input-field"
          />
        </div>
        <div className="form-group">
          <label>Country:</label>
          <input
            type="text"
            name="country"
            value={cardData.country}
            onChange={handleChange}
            required
            className="input-field"
          />
        </div>
        <div className="form-group">
          <label>Additional Info:</label>
          <textarea
            name="additionalInfo"
            value={cardData.additionalInfo}
            onChange={handleChange}
            className="input-field textarea"
          />
        </div>
        <div className="form-group">
          <label>Upload Images:</label>
          <input
            type="file"
            name="image"
            onChange={handleImageChange}
            multiple // 允许选择多张图片
            className="input-field"
            ref={fileInputRef} // 绑定 ref
          />
        </div>
        <div className="form-group">
          <button type="submit" className="submit-button" disabled={isSubmitting}>
            {isSubmitting ? 'Publishing...' : 'Publish Card'}
          </button>
        </div>
      </form>

      {/* 自定义弹出框 */}
      <CustomDialog 
        isOpen={isDialogOpen} 
        onClose={closeDialog} 
        title={dialogTitle} 
        message={dialogMessage} 
      />
    </div>
  );
};

export default CardPublishPage;
