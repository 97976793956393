import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min'; // 确保引入了 Bootstrap 的 JavaScript
import UserManagementPage from './UserManagementPage';
import TLSUserManagementPage from './TLSUserManagementPage';
import BLSUserManagementPage from './BLSUserManagementPage';
import VFSUserManagementPage from './VFSUserManagementPage';
import ITAUserManagementPage from './ITAUserManagementPage';
import UserConfigPage from './UserConfigPage';
import FranceSlotGetPage from './FranceSlotGetPage';
import TurnitinUI from './TurnitinUI';
import BookStatus from './BookStatus';
import PublishCardPage from './PublishCardPage';

const FunctionSelectorPage = () => {
  const [activeComponent, setActiveComponent] = useState(null);

  const handleNavClick = (componentName) => {
    setActiveComponent(
      componentName === 'FranceSlotGetPage' ? <FranceSlotGetPage /> :
      componentName === 'UserManagementPage' ? <UserManagementPage /> :
      componentName === 'TLSUserManagementPage' ? <TLSUserManagementPage /> :
      componentName === 'BLSUserManagementPage' ? <BLSUserManagementPage /> :
      componentName === 'VFSUserManagementPage' ? <VFSUserManagementPage /> :
      componentName === 'ITAUserManagementPage' ? <ITAUserManagementPage /> :
      componentName === 'UserConfigPage' ? <UserConfigPage /> :
      componentName === 'TurnitinUI' ? <TurnitinUI /> :
      componentName === 'BookStatus' ? <BookStatus /> :
      componentName === 'PublishCardPage' ? <PublishCardPage /> :
      null
    );

    // Collapse the navbar after clicking a menu item
    const navbarCollapse = document.getElementById('navbarNav');
    if (navbarCollapse.classList.contains('show')) {
      navbarCollapse.classList.remove('show');
    }
  };

  return (
    <div className="vh-100 d-flex flex-column">
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container-fluid">
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <button
                  className="nav-link btn btn-link"
                  onClick={() => handleNavClick('FranceSlotGetPage')}
                >
                  TROOV抢签
                </button>
              </li>
              <li className="nav-item">
                <button
                  className="nav-link btn btn-link"
                  onClick={() => handleNavClick('UserManagementPage')}
                >
                  TROOV管理
                </button>
              </li>
              <li className="nav-item">
                <button
                  className="nav-link btn btn-link"
                  onClick={() => handleNavClick('BookStatus')}
                >
                  TROOV预约管理
                </button>
              </li>
              <li className="nav-item">
                <button
                  className="nav-link btn btn-link"
                  onClick={() => handleNavClick('UserConfigPage')}
                >
                  TROOV下发配置
                </button>
              </li>
              <li className="nav-item">
                <button
                  className="nav-link btn btn-link"
                  onClick={() => handleNavClick('TurnitinUI')}
                >
                  Turnitin管理
                </button>
              </li>
              <li className="nav-item">
                <button
                  className="nav-link btn btn-link"
                  onClick={() => handleNavClick('ITAUserManagementPage')}
                >
                  ITA管理
                </button>
              </li>
              <li className="nav-item">
                <button
                  className="nav-link btn btn-link"
                  onClick={() => handleNavClick('TLSUserManagementPage')}
                >
                  TLS管理
                </button>
              </li>
              <li className="nav-item">
                <button
                  className="nav-link btn btn-link"
                  onClick={() => handleNavClick('VFSUserManagementPage')}
                >
                  VFS管理
                </button>
              </li>
              <li className="nav-item">
                <button
                  className="nav-link btn btn-link"
                  onClick={() => handleNavClick('BLSUserManagementPage')}
                >
                  BLS管理
                </button>
              </li>
              <li className="nav-item">
                <button
                  className="nav-link btn btn-link"
                  onClick={() => handleNavClick('PublishCardPage')}
                >
                  发布卡片
                </button>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      <div className="flex-grow-1 d-flex justify-content-center align-items-center w-100 p-3">
        <div className="w-100 h-100 border rounded p-3">
          {activeComponent}
        </div>
      </div>
    </div>
  );
};

export default FunctionSelectorPage;
